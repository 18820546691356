import React from "react"
import { graphql, Link } from "gatsby"
import palette from "../design/palette"
import convert from "../utils/convert"

interface ThansPageTemplateProps {
  title: string,
  text: string
}

const ThanksPageTemplate: React.FC<ThansPageTemplateProps> = ({
  title, text
}) => (
  <main
    id="thanks-page"
    className="flex items-center justify-center text-center my-24 md:mt-12"
  >
    <div className="mx-auto w-full max-w-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 426.667 426.667"
        className="w-24 mx-auto"
        style={{ fill: palette.accent }}
      >
        <path
          d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333 c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622 l140.894-140.898l31.309,31.309L174.199,322.918z"
        />
      </svg>

      <h1 className="my-12 text-4xl font-bold tracking-tight 2xs:leading-none">
        {title}
      </h1>

      <div
        className="markdown"
        dangerouslySetInnerHTML={{
          __html: convert(text)
        }}
      />

      <div className="flex justify-center items-center mt-12 sm:flex-col-reverse sm:mt-4">
        <Link
          to="/"
          id="thanks-page_go-back_link"
          className="flex items-center mr-4 border-2 px-6 py-2 rounded-sm sm:mr-0 sm:mt-4"
        >
          <svg
            viewBox="0 0 24 24"
            width={20}
            height={20}
          >
            <path
              fill="currentColor"
              d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"
            />
          </svg>

          <span>
            Gå tilbake
          </span>
        </Link>

        <a
          id="thanks-page_bonum-projects_link"
          href="https://bonum.no/prosjekter"
          target="_blank"
          rel="noopener"
          className="mr-4 btn-primary sm:mr-0"
        >
          Utforsk mer
        </a>
      </div>
    </div>
  </main>
)

interface ThanksPageProps {
  data: {
    markdownRemark: {
      frontmatter: ThansPageTemplateProps
    }
  }
}

const ThanksPage: React.FC<ThanksPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <ThanksPageTemplate
      title={frontmatter.title}
      text={frontmatter.text}
    />
  )
}

export default ThanksPage

export const thanksPageQuery = graphql`
  query ThanksPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        text
      }
    }
  }
`
